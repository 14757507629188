
  import { Component, Prop } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'

  import BaseBar from '@/layouts/BaseBar'
  import { Crumb } from '@/store/resources/form/state'
  import GBreadcrumbs from '@/components/GBreadcrumbs.vue'

@Component({
  components: { GBreadcrumbs },
  computed: {
    ...mapGetters('resources/form', ['crumbs']),
  },
})
  export default class AppBar extends BaseBar {
  @Prop({ type: Array }) nested?: Array<string>

  crumbs!: Array<Crumb>

  goBack () {
    const { nested } = this
    if (nested?.length) return

    return this.$router.back()
  }
  }
